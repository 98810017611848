import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroTheOther from "../components/HeroTheOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import Brands from "../components/base/Brands";
import WhyChooseUs from "../components/base/WhyChooseUs";
import Testimonial from "../components/base/Testimonial"
import CustomFooter from "../components/CustomFooter";

export const DynamicPenetrationTestingPageTemplate = ({
  seo,
  hero,
  contactus,
  dptsection,
  ptfsection,
  wgasection,
  wivsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" article_section={seo.article_section} blogservice={true} />
      <HeroTheOther data={hero} />
      <section className="section custom-section">
        <div className="container" style={{ textAlign: 'center' }}>
          <SubTitle title={contactus.title} style={{ fontSize: '1.2rem' }} />
          <div style={{ marginTop: '1rem' }}>
            <a href={contactus.buttonlink}>
              <button className="btn-light">{contactus.buttontxt} &#10230;</button>
            </a>
          </div>
        </div>
      </section>
      <section className="section custom-section" style={{ background: `${dptsection.sectionbg}` }}>
        <div className="container">
          <Title title={dptsection.title} color={dptsection.textcolor} />
          <Title title={dptsection.secondtitle} color={dptsection.textcolor} />
          <SubTitle title={dptsection.subheader} color={dptsection.textcolor} />
          <Descriptions descriptions={dptsection.descriptions} color={dptsection.textcolor} margin="0 0 1rem" />
          <div className="columns" >
            <div className="column is-8">
              <a href={contactus.buttonlink}>
                <button className="btn-light">Have Our Security Experts Test Your Mobile Apps Today &#10230;</button>
              </a>
            </div>
            <div className="column is-4 mobile-center">
              <div className="hero-image" style={{ position: "absolute", width: 160, bottom: -105, right: '-14px', maxWidth: 300, margin: "auto" }}>
                <PreviewCompatibleImage imageInfo={{ image: '/img/Cypress-Data-Defense-Website-Yellow-Alien-5.png' }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section custom-section" style={{ background: `${ptfsection.sectionbg}` }}>
        <div className="container">
          <Title title={ptfsection.title} color={ptfsection.textcolor} />
          <Title title={ptfsection.secondtitle} color={ptfsection.textcolor} />
          <SubTitle title={ptfsection.subheader1} color={ptfsection.textcolor} margin="0 0 1.5rem" />
          <Descriptions descriptions={ptfsection.descriptions1} color={ptfsection.textcolor} margin="1rem 0" />
          <a href={contactus.buttonlink}>
            <button>Call Us &#10230;</button>
          </a>
        </div>
      </section>
      <section className="section custom-section" style={{ background: `${dptsection.sectionbg}` }}>
        <div className="container">
          <SubTitle title="Powerful Mobile Application Penetration Security Testing Services" color="#fff" margin="0 0 1.5rem" />
          <Descriptions descriptions={[
            'With deep knowledge and expertise in conducting penetration testing on iOS, Android, and all other mobile platform applications, we understand the unique security challenges and vulnerabilities that come with each mobile architecture.',
            'We are experienced developers as well as testers, and we know how to develop and test mobile applications. We can conduct reliable pen testing on running applications to identify security issues that others tend to miss during app security testing.',
            'If you want to test your applications for security vulnerabilities, you’re at the right place.'
          ]} color={dptsection.textcolor} margin="0 0 1rem" />
          <div className="columns" >
            <div className="column is-8">
              <div style={{ marginTop: '1rem' }}>
                <a href={contactus.buttonlink}>
                  <button className="btn-light">Secure Your Mobile Apps Today &#10230;</button>
                </a>
              </div>
            </div>
            <div className="column is-4 mobile-center">
              <div className="pmasection_img hero-image">
                <PreviewCompatibleImage imageInfo={{ image: '/img/mobile_testing_services.png' }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Brands />
      <WhyChooseUs
        title="We offer reliable and specialized penetration testing services for your mobile applications."
        list={[{
          title: 'We are security experts who train others.',
          para: 'Our application security specialists regularly instruct for large corporations and global training institutions. We teach developers and organizations how to properly perform mobile application penetration testing.'
        }, {
          title: 'We are all developers and we understand code.',
          para: 'We aren’t only experts in security, we also know how mobile applications are (and SHOULD be) built. We can leverage this knowledge to provide the most thorough security reviews for your mobile apps.',
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Want to Test the Security of Your Mobile Applications?',
        subtitle: '',
        para: [
          'Our specialists can identify security vulnerabilities using powerful pen testing and static analysis services for your mobile applications. Let us help you develop secure applications.'
        ],
        linkTitle: 'Talk to Our Security Experts Today'
      }} />
    </div>
  )

DynamicPenetrationTestingPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  dptsection: PropTypes.object,
  ptfsection: PropTypes.object,
  wgasection: PropTypes.object,
  wivsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const DynamicPenetrationTestingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <DynamicPenetrationTestingPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        dptsection={frontmatter.dptsection}
        ptfsection={frontmatter.ptfsection}
        wgasection={frontmatter.wgasection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

DynamicPenetrationTestingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default DynamicPenetrationTestingPage

export const pageQuery = graphql`
  query DynamicPenetrationTestingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "dynamic-penetration-testing" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        dptsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          sidedescriptions {
            title
            list {
              title
              description
            }
          }
          bottomdescriptions {
            title
            list {
              title
              description
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ptfsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader1
          subheader1a
          descriptions1
          subheader2
          descriptions2
          subheader3
          descriptions3
          subheader4
          descriptions4
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        wgasection {
          title
          secondtitle
          subheader
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
